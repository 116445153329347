import React from "react";

const Services = ({ classicHeader, darkTheme }) => {
  // services details
  const services = [
    {
      name: "Web Design",
      desc: "In today's mobile-driven world, it's crucial to have a website that looks and functions flawlessly across all devices. We specialize in responsive web design, ensuring your site adapts seamlessly to smartphones, tablets, and desktops, providing an exceptional user experience on every screen size.",
      icon: "fas fa-desktop",
    },
    {
      name: "UI/UX Design",
      desc: "Our small team works closely with you to create a unique, visually appealing, and user-friendly website tailored to your specific needs and goals. Custom designs allow us to deliver a product that align with your brand and enhance your user's experience.",
      icon: "fas fa-pencil-ruler",
    },
    {
      name: "App Design & Develop",
      desc: "We're committed to delivering not only exceptional app design but also rapid development turnaround times without compromising on quality. Whether you have a groundbreaking idea or need to revamp an existing app, we're here to swiftly transform your vision into reality.",
      icon: "fas fa-paint-brush",
    },
    {
      name: "SEO Marketing",
      desc: "What good is a stunning website if no one can find it? We incorporate search engine optimization (SEO) best practices into our web designs, increasing your online visibility and helping you rank higher on search engines.",
      icon: "fas fa-bullhorn",
    },
  ];

  return (
    <section id="services" className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2 className={"text-24  text-uppercase fw-600 w-100 mb-0 " + (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")}>Services</h2>
          <p className={"text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " + (darkTheme ? "text-white" : "text-dark")}>
            What We Do?
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* content start */}
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              {services.length > 0 &&
                services.map((service, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="featured-box style-3 mb-5">
                      <div className={"featured-box-icon text-primary  shadow-sm rounded " + (darkTheme ? "bg-dark-1" : "bg-white")}>
                        <i className={service.icon} />
                      </div>
                      <h3 className={darkTheme ? "text-white" : ""}>{service.name}</h3>
                      <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>{service.desc}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* content end */}
      </div>
    </section>
  );
};

export default Services;
