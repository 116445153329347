import React from "react";
// import resumeFile from "../documents/resume.pdf";

const AboutUs = ({ classicHeader, darkTheme }) => {
  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2 className={"text-24  text-uppercase fw-600 w-100 mb-0 " + (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")}>About</h2>
          <p className={"text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " + (darkTheme ? "text-white" : "text-dark")}>
            Know Us More
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About us content start */}
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            <h2 className={"text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")}>
              We're <span className="text-primary">Web Developers</span>
            </h2>
            <p className={darkTheme ? "text-white-50" : ""}>
              We founded Sapling Studios with the vision of supporting small to medium-sized businesses on their digital journey. Our small team is dedicated to
              crafting bespoke software solutions that drive growth and efficiency for your enterprise.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              Our motto is to consistently deliver projects on time and within budget, while meeting our clients' specific requirements. We're committed to
              equipping our clients with the tools and strategies needed to thrive in the competitive landscape of the modern digital world.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
